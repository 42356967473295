import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { OPT_IN_ALL_EVENT, BANNER_PRE_DECISION_SHOW_EVENT } from "../../types";
import { applyContentBlockRatioCssPlainCss, customUnblockTriggers, getSsrPoweredByLink, getUserDecision } from "..";
import { getCurrentTcfStringFromCookie, transformTcfSrc } from "../../tcf";
import { getOtherOptionsFromWindow } from "../../utils";
import { render, unmountComponentAtNode } from "react-dom";
import { WebsiteBlocker } from "../../components";
import { applyJQueryEventInitiator, UnblockWatcher } from "@devowl-wp/headless-content-unblocker";
/**
 * Listen to opt-in of all cookies so we can enable the blocked content or
 * create an visual content blocker. It also listens if no / or invalid consent is given.
 */

function listenOptInForContentBlocker() {
  var acceptedCookies = [];

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      setVisualParentIfClassOfParent = _getOtherOptionsFromW.setVisualParentIfClassOfParent,
      multilingualSkipHTMLForTag = _getOtherOptionsFromW.multilingualSkipHTMLForTag,
      dependantVisibilityContainers = _getOtherOptionsFromW.dependantVisibilityContainers,
      allBlocker = _getOtherOptionsFromW.blocker;

  var watcher = new UnblockWatcher({
    checker: function checker(by, requiredIds, blockerId) {
      var _allBlocker$filter;

      var blocker = (_allBlocker$filter = allBlocker.filter(function (_ref) {
        var id = _ref.id;
        return id === blockerId;
      })) === null || _allBlocker$filter === void 0 ? void 0 : _allBlocker$filter[0];
      var consent = true;

      if (by === "cookies") {
        consent = requiredIds.map(function (requiredId) {
          var _iterator = _createForOfIteratorHelper(acceptedCookies),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var id = _step.value.cookie.id;

              if (id === requiredId) {
                return true;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          return false;
        }).indexOf(false) === -1;
      }

      if (process.env.IS_TCF === "1" && by === "tcfVendors") {
        var tcfData = getCurrentTcfStringFromCookie();

        if (!tcfData) {
          // No consent given yet, fallback to our decision (e.g. Country Bypass and GDPR does not apply for TCF)
          var decision = getUserDecision();
          consent = decision && getOtherOptionsFromWindow().revisionHash === decision.revision;
        } else {
          var model = tcfData.model; // Map vendor IDs to "has consent" and detect if all have consent

          consent = requiredIds.map(function (vendorId) {
            return model.vendorConsents.has(vendorId) || model.vendorLegitimateInterests.has(vendorId);
          }).indexOf(false) === -1;
        }
      }

      return {
        consent: consent,
        blocker: blocker
      };
    },
    overwriteAttributeValue: function overwriteAttributeValue(value, originalAttribute) {
      if (process.env.IS_TCF === "1" && originalAttribute === "src") {
        return transformTcfSrc(value);
      }

      return value;
    },
    transactionClosed: function transactionClosed(unblockedNodes) {
      customUnblockTriggers(unblockedNodes);
    },
    visual: {
      setVisualParentIfClassOfParent: setVisualParentIfClassOfParent,
      dependantVisibilityContainers: dependantVisibilityContainers,
      unmount: function unmount(contentBlocker) {
        unmountComponentAtNode(contentBlocker);
      },
      mount: function mount(container, blocker, _, setVisualAsLastClickedVisual) {
        // Do not translate this content blocker with an output buffer plugin like TranslatePress or Weglot
        if (multilingualSkipHTMLForTag) {
          container.setAttribute(multilingualSkipHTMLForTag, "1");
        }

        render(h(WebsiteBlocker, {
          poweredLink: getSsrPoweredByLink(),
          blocker: blocker,
          setVisualAsLastClickedVisual: setVisualAsLastClickedVisual
        }), container);
      }
    },
    customInitiators: function customInitiators(ownerDocument, defaultView) {
      // [Plugin comp]: TODO: extract to configuration
      applyJQueryEventInitiator(ownerDocument, defaultView, "elementor/frontend/init");
      applyJQueryEventInitiator(ownerDocument, defaultView, "tcb_after_dom_ready"); // Thrive Architect

      applyJQueryEventInitiator(ownerDocument, ownerDocument, "tve-dash.load", {
        // Mark requests as already sent as blocked modules in Thrive never gets loaded again
        onBeforeExecute: function onBeforeExecute() {
          var _ref2 = window,
              TVE_Dash = _ref2.TVE_Dash;
          TVE_Dash.ajax_sent = true;
        }
      }); // Thrive Leads
    }
  });
  document.addEventListener(OPT_IN_ALL_EVENT, function (_ref3) {
    var cookies = _ref3.detail.cookies;
    acceptedCookies = cookies;
    watcher.unblockNow();
    watcher.start();
  });
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, function () {
    acceptedCookies = [];
    watcher.unblockNow();
    watcher.start();
  });
  applyContentBlockRatioCssPlainCss();
}

export { listenOptInForContentBlocker };
import _extends from "@babel/runtime/helpers/extends";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Fragment, useState, useMemo } from "react";
import { useBlocker } from "../../contexts";
import { BlockerBodyInlineStyles, BlockerInlineStyles, BlockerButton, BlockerGroupsInlineStyles, BlockerCookie } from ".";
import { getOtherOptionsFromWindow } from "../../utils";
import { expandGroupsServiceVariable } from "../../others";
import { BannerDevHint } from "../banner";

var BlockerBody = function BlockerBody() {
  var blocker = useBlocker();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      areCookiesVisible = _useState2[0],
      setCookiesVisible = _useState2[1];

  var isEPrivacyUSAEnabled = blocker.ePrivacyUSA,
      isAgeNotice = blocker.ageNotice,
      teachingsSeparatorActive = blocker.bodyDesign.teachingsSeparatorActive,
      _blocker$decision = blocker.decision,
      acceptAll = _blocker$decision.acceptAll,
      acceptIndividual = _blocker$decision.acceptIndividual,
      _blocker$texts = blocker.texts,
      ePrivacyUSA = _blocker$texts.ePrivacyUSA,
      ageNoticeBlocker = _blocker$texts.ageNoticeBlocker,
      blockerLoadButton = _blocker$texts.blockerLoadButton,
      blockerLinkShowMissing = _blocker$texts.blockerLinkShowMissing,
      blockerAcceptInfo = _blocker$texts.blockerAcceptInfo,
      _blocker$blocker = blocker.blocker,
      description = _blocker$blocker.description,
      cookies = _blocker$blocker.cookies,
      consent = blocker.consent,
      groups = blocker.groups,
      onUnblock = blocker.onUnblock;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      close = _getOtherOptionsFromW.bannerI18n.close; // Calculate missing cookies


  var missing = useMemo(function () {
    var missing = [];
    var allConsentCookies = [];

    for (var _i = 0, _Object$values = Object.values(consent.groups); _i < _Object$values.length; _i++) {
      var _cookies = _Object$values[_i];
      allConsentCookies.push.apply(allConsentCookies, _toConsumableArray(_cookies));
    }

    var _iterator = _createForOfIteratorHelper(groups),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var items = _step.value.items;

        var _iterator2 = _createForOfIteratorHelper(items),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var cookie = _step2.value;

            // Cookie is needed for this blocker? Yes, check if already consent...
            if (cookies.indexOf(cookie.id) > -1 && allConsentCookies.indexOf(cookie.id) === -1) {
              missing.push(cookie);
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return missing;
  }, [groups, cookies, consent]); // Only enable USA notice when the blocked service is USA-relevant

  var isEPrivacyUSA = isEPrivacyUSAEnabled ? missing.map(function (_ref) {
    var ePrivacyUSA = _ref.ePrivacyUSA;
    return ePrivacyUSA;
  }).filter(Boolean).length > 0 : false;
  var useEPrivacyUSA = expandGroupsServiceVariable(groups, // TCF does currently not support visual blockers
  undefined, isEPrivacyUSA ? ePrivacyUSA : "", function (_ref2) {
    var ePrivacyUSA = _ref2.ePrivacyUSA;
    return ePrivacyUSA;
  });
  var showDescriptionContainer = !!description || isEPrivacyUSA || isAgeNotice;
  return h("div", BlockerBodyInlineStyles.bodyContainer(blocker), h("div", BlockerBodyInlineStyles.body(blocker), h("div", BlockerInlineStyles.topSide(blocker), showDescriptionContainer && h("div", BlockerBodyInlineStyles.description(blocker), !!description && h(Fragment, null, h("span", {
    dangerouslySetInnerHTML: {
      __html: description.replace(/\n/gm, "<br />")
    }
  }), teachingsSeparatorActive && h("div", null, h("span", BlockerBodyInlineStyles.teachingsSeparator(blocker)))), h(Fragment, null, !!useEPrivacyUSA && h("span", _extends({}, BlockerBodyInlineStyles.teaching(blocker), {
    dangerouslySetInnerHTML: {
      __html: useEPrivacyUSA
    }
  })), isAgeNotice && !!ageNoticeBlocker && h("span", _extends({}, BlockerBodyInlineStyles.teaching(blocker), {
    dangerouslySetInnerHTML: {
      __html: ageNoticeBlocker
    }
  })), h("span", _extends({}, BlockerBodyInlineStyles.teaching(blocker), {
    dangerouslySetInnerHTML: {
      __html: blockerAcceptInfo
    }
  })))), h(BlockerButton, {
    type: acceptIndividual === "hide" ? "link" : acceptIndividual,
    inlineStyle: "showInfo",
    onClick: function onClick() {
      return setCookiesVisible(!areCookiesVisible);
    }
  }, areCookiesVisible ? close : blockerLinkShowMissing), areCookiesVisible && h("div", BlockerGroupsInlineStyles.cookieScroll(blocker), missing.map(function (cookie) {
    return h(BlockerCookie, {
      key: cookie.id,
      cookie: cookie
    });
  }))), h("div", BlockerInlineStyles.bottomSide(blocker), h(BlockerButton, {
    type: acceptAll === "hide" ? "button" : acceptAll,
    inlineStyle: "save",
    onClick: function onClick(e) {
      return onUnblock(e);
    }
  }, blockerLoadButton), h(BannerDevHint, null))));
};

export { BlockerBody };
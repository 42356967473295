import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import { getOtherOptionsFromWindow } from "../utils";
import { BlockerContextFactory } from "../contexts";
import { Blocker } from ".";
import { adjustConsentComingFromContentBlocker, getUserDecision } from "../others";
import { usePlainCss } from "../hooks";
import { HTML_ATTRIBUTE_COOKIE_IDS } from "@devowl-wp/headless-content-unblocker";
/**
 * Simply connects a context store to the blocker itself. On the website itself
 * it should not rely
 */

var WebsiteBlocker = function WebsiteBlocker(_ref) {
  var poweredLink = _ref.poweredLink,
      blocker = _ref.blocker,
      setVisualAsLastClickedVisual = _ref.setVisualAsLastClickedVisual;
  var userDecision = getUserDecision();

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.customizeValuesBanner,
      layout = _getOtherOptionsFromW2.layout,
      decision = _getOtherOptionsFromW2.decision,
      legal = _getOtherOptionsFromW2.legal,
      design = _getOtherOptionsFromW2.design,
      headerDesign = _getOtherOptionsFromW2.headerDesign,
      bodyDesign = _getOtherOptionsFromW2.bodyDesign,
      footerDesign = _getOtherOptionsFromW2.footerDesign,
      texts = _getOtherOptionsFromW2.texts,
      individualLayout = _getOtherOptionsFromW2.individualLayout,
      saveButton = _getOtherOptionsFromW2.saveButton,
      group = _getOtherOptionsFromW2.group,
      individualTexts = _getOtherOptionsFromW2.individualTexts,
      customCss = _getOtherOptionsFromW2.customCss,
      pageIdToPermalink = _getOtherOptionsFromW.pageIdToPermalink,
      consentForwardingExternalHosts = _getOtherOptionsFromW.consentForwardingExternalHosts,
      isTcf = _getOtherOptionsFromW.isTcf,
      isEPrivacyUSA = _getOtherOptionsFromW.isEPrivacyUSA,
      isAgeNotice = _getOtherOptionsFromW.isAgeNotice,
      groups = _getOtherOptionsFromW.groups; // Calculate a border color for the complete box as the standard box does not have one configured


  var overwriteDesign = {
    borderWidth: design.borderWidth || 1,
    borderColor: design.borderWidth === 0 ? headerDesign.borderWidth > 0 ? headerDesign.borderColor : footerDesign.borderWidth > 0 ? footerDesign.borderColor : design.fontColor : design.borderColor
  }; // Context values preparation

  var _useState = useState({
    layout: _objectSpread({}, layout),
    decision: _objectSpread({}, decision),
    legal: _objectSpread({}, legal),
    design: _objectSpread(_objectSpread({}, design), overwriteDesign),
    headerDesign: _objectSpread({}, headerDesign),
    bodyDesign: _objectSpread({}, bodyDesign),
    footerDesign: _objectSpread({}, footerDesign),
    texts: _objectSpread({}, texts),
    individualLayout: _objectSpread({}, individualLayout),
    saveButton: _objectSpread({}, saveButton),
    group: _objectSpread({}, group),
    individualTexts: _objectSpread({}, individualTexts),
    customCss: _objectSpread({}, customCss),
    pageIdToPermalink: pageIdToPermalink,
    consentForwardingExternalHosts: consentForwardingExternalHosts,
    groups: groups,
    poweredLink: poweredLink,
    isTcf: isTcf,
    ePrivacyUSA: isEPrivacyUSA,
    ageNotice: isAgeNotice,
    blocker: blocker,
    consent: {
      groups: _objectSpread({}, userDecision === false ? {} : userDecision.consent)
    },
    onUnblock: function onUnblock(event) {
      adjustConsentComingFromContentBlocker(blocker);
      setVisualAsLastClickedVisual(event);
    }
  }),
      _useState2 = _slicedToArray(_useState, 1),
      contextValue = _useState2[0];

  usePlainCss([".elementor-background-overlay ~ [".concat(HTML_ATTRIBUTE_COOKIE_IDS, "] { z-index: 99; }")].join(""));
  var BlockerContext = BlockerContextFactory.Context();
  return h(BlockerContext.Provider, {
    value: contextValue
  }, h(Blocker, null));
};

export { WebsiteBlocker };
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY } from "../dom";
import { decideToUnblock } from ".";
/**
 * Read all HTML elements and check, if they can be enabled by a consent.
 */

function findBlockedNodes(checker) {
  var result = [];
  var nodes = Array.prototype.slice.call(document.querySelectorAll("[".concat(HTML_ATTRIBUTE_COOKIE_IDS, "]")));

  var _iterator = _createForOfIteratorHelper(nodes),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var node = _step.value;

      var _decideToUnblock = decideToUnblock(node.getAttribute(HTML_ATTRIBUTE_BY), node.getAttribute(HTML_ATTRIBUTE_COOKIE_IDS), +node.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID), checker),
          blocker = _decideToUnblock.blocker,
          consent = _decideToUnblock.consent;

      result.push({
        node: node,
        consent: consent,
        blocker: blocker
      });
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return result;
}

export { findBlockedNodes };
import { isCurrentlyInTransaction, OPT_IN_CONTENT_BLOCKER_ALL } from "@devowl-wp/headless-content-unblocker";
var overwritten = false;
/**
 * Overwrite `jQuery(fn)` as they can not be triggered by our script blocker.
 *
 * @see https://api.jquery.com/ready/
 */

function applyJQueryReadyInitiator(doc) {
  // Only overwrite once
  if (overwritten) {
    return;
  }

  var _ref = doc.defaultView || doc.parentWindow,
      jQuery = _ref.jQuery;

  if (!jQuery) {
    return;
  }

  var originalFn = jQuery.fn.ready;

  jQuery.fn.ready = function (fn) {
    if (fn) {
      if (isCurrentlyInTransaction()) {
        // Standard behavior: jQuery.ready() got called with the unblocked script
        var executed = false;
        document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, function () {
          // IE does not support the `once` option
          if (executed) {
            return;
          }

          executed = true; // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7

          setTimeout(function () {
            fn(jQuery);
          }, 0);
        });
      } else {
        // Edge case: jQuery.ready() got called multiple times and nested
        // Example:
        // <script>
        // console.log("gravatar.com/avatar");
        // jQuery(function() {
        //   console.log("unblocked");
        //   jQuery(function() {
        //     console.log("unblocked 2");
        //   });
        // });
        // </script>
        setTimeout(function () {
          fn(jQuery);
        }, 0);
      }
    }

    return originalFn(function () {// Silence is golden.
    });
  };

  overwritten = true;
}

export { applyJQueryReadyInitiator };
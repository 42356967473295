import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { parents, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_INLINE, HTML_ATTRIBUTE_INLINE_STYLE, HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN } from "..";
import { probablyResetParentContainerForVisual, isPotentialVisualWithinResponsiveContainer, findVisualParent, findPreviousVisibleDuplicate } from ".";
var connectedCounter = 0;
/**
 * Save the identifier for the last clicked content blocker so we can
 * dispatch in our event if it is the clicked one. This is useful e.g.
 * to delegate the click event further and start a video.
 */

var lastClickedConnectedCounter = undefined;

/**
 * Create visual content blocker for a given node.
 */
function createVisual(_ref) {
  var _node$style;

  var node = _ref.node,
      blocker = _ref.blocker,
      setVisualParentIfClassOfParent = _ref.setVisualParentIfClassOfParent,
      dependantVisibilityContainers = _ref.dependantVisibilityContainers,
      mount = _ref.mount;

  // Short circuit: We do not know a blocker for this; do nothing
  if (!blocker) {
    return;
  }

  var parentElement = node.parentElement;
  var _blocker$forceHidden = blocker.forceHidden,
      forceHidden = _blocker$forceHidden === void 0 ? false : _blocker$forceHidden,
      visual = blocker.visual,
      blockerId = blocker.id;
  var position = ((_node$style = node.style) === null || _node$style === void 0 ? void 0 : _node$style.position) || "initial"; // Visual content blockers are only allowed for "real" content

  var isPositioned = ["fixed", "absolute", "sticky"].indexOf(position) > -1;
  var isNotInContent = [document.body, document.head, document.querySelector("html")].indexOf(parentElement) > -1;
  var isAlreadyConnected = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);

  var _findVisualParent = findVisualParent(node, setVisualParentIfClassOfParent || {}),
      _findVisualParent2 = _slicedToArray(_findVisualParent, 2),
      createBefore = _findVisualParent2[0],
      visualParentMode = _findVisualParent2[1];

  var isInlineScriptOrStyle = createBefore.hasAttribute(HTML_ATTRIBUTE_INLINE) || createBefore.hasAttribute(HTML_ATTRIBUTE_INLINE_STYLE);
  var isVisible = !!createBefore.offsetParent; // Always hide elements (again) if no visual blocker is possible (get) and memorize
  // the "!important" state so we can correctly reset it in `probablyResetParentContainerForVisual`

  var fnStrictHide = function fnStrictHide() {
    if (["script", "link"].indexOf(node === null || node === void 0 ? void 0 : node.tagName.toLowerCase()) === -1 && visualParentMode !== "childrenSelector") {
      var style = node.style;

      if (style.getPropertyValue("display") === "none" && style.getPropertyPriority("display") === "important") {
        node.setAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN, "1");
      } else {
        style.setProperty("display", "none", "important");
      }
    }
  };

  if (isNotInContent || isPositioned && !isPotentialVisualWithinResponsiveContainer(node) && !forceHidden || !visual || isAlreadyConnected || !isInlineScriptOrStyle && !isVisible && !forceHidden) {
    // Check if this content blocker is inside a known container for which we need to wait first its visibility
    if (!isVisible && dependantVisibilityContainers) {
      var dependantContainers = parents(node, dependantVisibilityContainers.join(","));

      if (dependantContainers.length > 0 && !dependantContainers[0].offsetParent) {
        // Wait for visibility, do nothing with this node...
        return;
      }
    }

    fnStrictHide();
    return;
  }

  var container = document.createElement("div"); // Avoid duplicate visual blockers and connect them

  var foundDuplicate = findPreviousVisibleDuplicate(createBefore, blockerId);

  if (foundDuplicate) {
    node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, foundDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED));
    fnStrictHide();
    return;
  } // Connect blocker with blocked node


  container.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter.toString());
  container.setAttribute(HTML_ATTRIBUTE_BLOCKER_ID, blockerId.toString());
  container.className = "rcb-content-blocker";
  container.style.setProperty("max-height", "initial");
  node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter.toString());
  createBefore.parentNode.insertBefore(container, createBefore); // Connect a children selector with the content blocker above

  if (visualParentMode === "childrenSelector") {
    createBefore.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter.toString());
  } // Hide our element and render content blocker


  (visualParentMode === "childrenSelector" ? createBefore : node).style.setProperty("display", "none", "important");
  var connectedCounterCopy = connectedCounter;
  mount(container, blocker, connectedCounter, function (event) {
    // Stop above propagation so click event handlers do not handle this as a click on the
    // mainly blocked element (example: Video inside another container which delegates click event).
    event === null || event === void 0 ? void 0 : event.stopPropagation();
    setLastClickedConnectedCounter(connectedCounterCopy);
  });
  connectedCounter++;
  probablyResetParentContainerForVisual(createBefore, true);
}

function getLastClickedConnectedCounter() {
  return lastClickedConnectedCounter;
}

function setLastClickedConnectedCounter(connectedCounter) {
  lastClickedConnectedCounter = connectedCounter;
}

export { createVisual, getLastClickedConnectedCounter, setLastClickedConnectedCounter };
import { Fragment } from "react";
import { useBlocker } from "../../contexts";
import { BlockerContentInlineStyles, BlockerHeaderInlineStyles, BlockerFooterInlineStyles, BlockerFooter, BlockerHeader, BlockerBody } from ".";

var Blocker = function Blocker() {
  var blocker = useBlocker();
  var showFooter = blocker.showFooter;
  return h("div", BlockerContentInlineStyles.inner(blocker), h("div", BlockerContentInlineStyles.content(blocker), h(BlockerHeader, null), h("div", BlockerHeaderInlineStyles.headerSeparator(blocker)), h(BlockerBody, null), !!showFooter && h(Fragment, null, h("div", BlockerFooterInlineStyles.footerSeparator(blocker)), h(BlockerFooter, null))));
};

export { Blocker };
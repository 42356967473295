import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useLayoutEffect, useState } from "react";
import { BlockerGroupsInlineStyles, BlockerCookieProperty } from ".";
import { useBlocker } from "../../contexts";
import { getOtherOptionsFromWindow } from "../../utils";
import { getCookieTypeLocalized } from "../../others"; // eslint-disable-next-line import/no-extraneous-dependencies

import { PlusOutlined } from "@ant-design/icons-svg";
import { PlainAntdIconAsSvg } from "..";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";

var BlockerCookie = function BlockerCookie(_ref) {
  var _ref$cookie = _ref.cookie,
      name = _ref$cookie.name,
      purpose = _ref$cookie.purpose,
      provider = _ref$cookie.provider,
      providerPrivacyPolicy = _ref$cookie.providerPrivacyPolicy,
      ePrivacyUSA = _ref$cookie.ePrivacyUSA,
      noTechnicalDefinitions = _ref$cookie.noTechnicalDefinitions,
      technicalDefinitions = _ref$cookie.technicalDefinitions,
      codeDynamics = _ref$cookie.codeDynamics;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var blocker = useBlocker();
  var isEPrivacyUSA = blocker.ePrivacyUSA,
      descriptionFontSize = blocker.group.descriptionFontSize;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      bannerI18n = _getOtherOptionsFromW.bannerI18n;

  var types = getCookieTypeLocalized();
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  return h("div", BlockerGroupsInlineStyles.cookie(blocker), h("div", {
    style: {
      marginBottom: 10
    }
  }, h(PlainAntdIconAsSvg, _extends({
    icon: PlusOutlined
  }, BlockerGroupsInlineStyles.checkbox(blocker, isRendered, true, true, descriptionFontSize))), h("strong", {
    style: {
      verticalAlign: "middle"
    }
  }, name)), !!purpose && h(BlockerCookieProperty, {
    label: bannerI18n.purpose,
    value: purpose
  }), h(BlockerCookieProperty, {
    label: bannerI18n.provider,
    value: provider
  }), !!providerPrivacyPolicy && h(BlockerCookieProperty, {
    label: bannerI18n.providerPrivacyPolicy,
    value: providerPrivacyPolicy
  }), !!isEPrivacyUSA && h(BlockerCookieProperty, {
    label: bannerI18n.ePrivacyUSA,
    value: ePrivacyUSA ? bannerI18n.yes : bannerI18n.no
  }), !noTechnicalDefinitions && technicalDefinitions.map(function (_ref2) {
    var type = _ref2.type,
        name = _ref2.name,
        host = _ref2.host,
        duration = _ref2.duration,
        durationUnit = _ref2.durationUnit,
        sessionDuration = _ref2.sessionDuration;
    return h(BlockerCookieProperty, {
      key: name,
      label: bannerI18n.technicalCookieDefinition,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, applyDynamicsToHtml(name, codeDynamics))
    }, h(BlockerCookieProperty, {
      label: bannerI18n.type,
      value: types[type].name
    }), !!host && h(BlockerCookieProperty, {
      label: bannerI18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, host)
    }), ["local", "session", "indexedDb", "flash"].indexOf(type) === -1 && h(BlockerCookieProperty, {
      label: bannerI18n.duration,
      value: sessionDuration ? "Session" : "".concat(duration, " ").concat(bannerI18n.durationUnit[durationUnit])
    }));
  }));
};

export { BlockerCookie };
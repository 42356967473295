import { useBlocker } from "../../contexts";
import { BlockerHeaderInlineStyles } from ".";

var BlockerHeader = function BlockerHeader() {
  var blocker = useBlocker();
  var name = blocker.blocker.name,
      blockerHeadline = blocker.texts.blockerHeadline;
  return h("div", BlockerHeaderInlineStyles.headerContainer(blocker), h("div", BlockerHeaderInlineStyles.header(blocker), h("div", BlockerHeaderInlineStyles.headline(blocker), blockerHeadline.replace(/{{name}}/g, name))));
};

export { BlockerHeader };